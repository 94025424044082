var dataSet = [];

export function select_course_seller() {
    select2_course_id();
    create_datatable(dataSet);

    $('#seller_course_id').change(function() {
        dataSet = [];
        var itemSelected = $(this).find(":selected").val();
        getCourse(itemSelected);
        calcDescount();
    });
}

function create_datatable(dataSet) {
    $("#seller_payments").DataTable({
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.25/i18n/Portuguese-Brasil.json'
        },
        data: dataSet,
        columns: [
            { title: '' },
            { title: 'Mês' },
            { title: 'Vencimento' },
            { title: 'Mensalidade' },
            { title: 'Desconto' },
            { title: 'Valor Pago' },
            { title: 'Forma Pagamento' },
            { title: 'Pagamento' },
            { title: 'Observação' },
        ],
    });
}

function destroy_dataTable() {
    $("#seller_payments").DataTable().destroy();
}

function select2_course_id() {
    $('#seller_course_id').select2();
}

function getCourse(id) {
    $.get('/cursos/' + id, function(data) {
        fill_table_payments(data);
    });
}

function fill_table_payments(data) {
    destroy_dataTable();
    const meses = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];
    const numero_meses = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    var initial_date = new Date(data.initial_date);
    var mes = initial_date.getMonth();
    var ano = initial_date.getFullYear();

    var today = toDay();

    for (let i = 0; i < 1; i++) {
        dataSet.push([
            i + 1, meses[mes] + "/" + ano, 
            "<input type='date' class='form-control' value='" + ano + "-" + numero_meses[mes] + "-" + data.duo_date + "' disabled/>",
            "<input type='number' class='form-control' id='value' value='" + data.valor.toFixed(2) + "' disabled/>",
            "<input type='number' class='form-control' id='descount'/>",
            "<input type='number' id='total' class='form-control' value='" + data.valor.toFixed(2) + "' disabled />",
            "<select class='form-control '><option selected>Dinheiro</option><option>Pix</option><option>Boleto</option><option>Cartão (1X)</option><option>Cartão (2X)</option><option>Cartão (3X)</option><option>Cartão (4X)</option><option>Cartão (5X)</option><option>Cartão (6X)</option><option>Cartão (7X)</option><option>Cartão (8X)</option><option>Cartão (9X)</option><option>Cartão (10X)</option></select>",
            "<input type='date' class='form-control' value='" + today + "'/>",
            "<input type='text' class='form-control' id='note'/>",
        ]);
    }

    create_datatable(dataSet);
}

function toDay(){
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;
    return today
  }

function calcDescount() {
    setTimeout(() => {
        $('#note').val('');
        $('#descount').val('0');
        // $('#descount').maskMoney({ allowNegative: false, thousands: '', decimal: '.', affixesStay: false, precision: 0 });
        $('#descount').focusout(function() {
            var descount = $('#descount').val();
            var value = $('#value').val();
            var value_total = value - descount;

            if (value_total < 0) {
                $("#seller_payments").notify("Atenção: Desconto não Permitido");
                $('#total').val(value);
                $('#descount').val(0);
            } else {
                $("#seller_payments").notify("Desconto Aplicado!", "success");
                $('#total').val(value_total);
            }
        });
    }, 500);
}