export function select_course_registration() {
    $('#registration_product_id').select2({
        placeholder: 'Selecione um Produto',
    });

    $('#registration_product_id').change(function() {
        // $("#registration_descount").val(0);
        var itemSelected = $(this).find(":selected").text();
        var regExp = /\(([^)]+)\)/g;
        var valorSemDesconto = regExp.exec(itemSelected);
        $('#valor').html(parseFloat(valorSemDesconto[1]));

        $("#registration_descount").focusout(function() {
            $("#registration_note_descount").addClass('d-none');
            var desconto = $("#registration_descount").val();
            var valorComDesconto = parseFloat(valorSemDesconto[1]) - desconto;

            if (desconto > 0) {
                if (valorComDesconto < 0) {
                    $("#valor").notify("Desconto não Permitido");
                    $('#valor').html(parseFloat(valorSemDesconto[1]));
                } else {
                    $("#valor").notify("Desconto Aplicado!", "success", "center", "top");
                    $('#valor').html(valorComDesconto);
                    $("#registration_note_descount").removeClass('d-none');
                    $("#registration_note_descount").prop('required', 'true');
                }
            } else {
                $("#registration_note_descount").addClass('d-none');
                $("#registration_note_descount").removeAttr('required');
                $('#valor').html(parseFloat(valorSemDesconto[1]));
            }
        });
    });
}