require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("admin-lte")
require("jquery")
require("gritter/js/jquery.gritter.js")
require('datatables.net-bs4')
    // require("./courses")

import "bootstrap";
import "../stylesheets/application";
import "@fortawesome/fontawesome-free/js/all";

import './modules/common/jquery-maskmoney.js';
import './modules/common/notify.min.js';
import { select_course_seller } from "./sellers";

import $ from 'jquery';
global.$ = jQuery;

window.Rails = Rails

import 'select2';
require("select2")
import 'select2/dist/css/select2.css';
import { select_course_registration } from "./registrations"

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
    select_course_registration();
    select_course_seller();
});

$(document).on('select2:open', () => {
    document.querySelector('.select2-search__field').focus();
});